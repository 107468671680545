/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'record2-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M10 8a2 2 0 11-4 0 2 2 0 014 0"/><path pid="1" d="M8 13A5 5 0 108 3a5 5 0 000 10m0-2a3 3 0 110-6 3 3 0 010 6"/>',
    },
});
